import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../src/logo.png';

const FormPage = () => {
  const [step, setStep] = useState(1);
  const [otherText, setOtherText] = useState('');
  const totalSteps = 9;
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [step2Options, setStep2Options] = useState([]);
  const [step3Options, setStep3Options] = useState([]);
  const [step4Options, setStep4Options] = useState([]);
  const [step6Options, setStep6Options] = useState([]);
  const [step7OtherOption, setStep7OtherOption] = useState(false);
  const [step6OtherOption, setStep6OtherOption] = useState(false);  
  const [step7Options, setStep7Options] = useState([]);
  const [step3OtherOption, setStep3OtherOption] = useState(false);
  const [step4OtherOption, setStep4OtherOption] = useState(false);
  
  const [formData, setFormData] = useState({
    position: '',
    otherPosition: '',
    selectedOptions: [],
    companyName: '',
    name: '',
    phoneNumber: '',
    email: '',
    budget: '',
    maturityLevel: '',
    currentStep: 1,
  });

  const navigate = useNavigate();


  const nextStep = () => {
    if (step === 3) {
      setStep3OtherOption(false);
    } else if (step === 4) {
      setStep4OtherOption(false);
    }
    setOtherText('');
    setStep((prevStep) => Math.min(prevStep + 1, totalSteps));
    setFormData((prevData) => ({
      ...prevData,
      currentStep: Math.min(prevData.currentStep + 1, totalSteps),
    }));
  };
  
  const nextStepWithValidation = () => {
    if (
      (step === 3 && step3Options.length === 0 && !otherText) ||
      (step === 4 && step4Options.length === 0 && !otherText)
    ) {
      alert('Por favor, selecione pelo menos uma opção.');
      return;
    }
    handleNextStepAndSendData();
  };
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsAnalyzing(true);
  
    setTimeout(() => {
      const organizedData = {
        ...formData,
        step2Options,
        step3Options,
        step4Options,
        step6Options,
        step7Options,
      };
  
      // Nova lógica: se no step 3 apenas a opção "Contabilidade" estiver selecionada
      if (step3Options.length === 1 && step3Options.includes("Contabilidade")) {
        sendToWebhook('https://hook.us2.make.com/c6xpasf2ofvkuzg211m35ixk6vw2yyhi', organizedData);
        navigate('/nao-10');
        return;
      }
  
      // Lógica para enviar o organizedData ao webhook com base nos critérios
      // Redireciona para '/nao-10' se step2Options = Até R$ 4,8 milhões /ano
      if (step2Options.includes('Até R$ 4,8 milhões /ano')) {
        sendToWebhook('https://hook.us2.make.com/c6xpasf2ofvkuzg211m35ixk6vw2yyhi', organizedData);
        navigate('/nao-10');
        return;
      }
  
      // Redireciona para '/quali-2' se step2Options tiver algum destes valores
      if (
        step2Options.includes('De R$ 4,8 milhões até R$ 10 milhões /ano') ||
        step2Options.includes('De R$ 10 milhões até R$ 15 milhões /ano') ||
        step2Options.includes('Acima de R$ 50 milhões /ano')
      ) {
        sendToWebhook('https://hook.us2.make.com/rmcectf0rcwdi90u27fety3oxnldll34', organizedData);
        navigate('/quali-2');
        return;
      }
  
      // Redireciona para '/quali-1' se step2Options = De R$ 15 milhões até R$ 18 milhões /ano ou De R$ 18 milhões até R$ 50 milhões /ano
      if (
        step2Options.includes('De R$ 15 milhões até R$ 18 milhões /ano') ||
        step2Options.includes('De R$ 18 milhões até R$ 50 milhões /ano')
      ) {
        if (['Gerente', 'Analista', 'Assistente', 'Estagiário', 'Consultor', 'Outro'].includes(formData.position)) {
          sendToWebhook('https://hook.us2.make.com/rmcectf0rcwdi90u27fety3oxnldll34', organizedData);
          navigate('/quali-2');
        } else {
          sendToWebhook('https://hook.us2.make.com/rmcectf0rcwdi90u27fety3oxnldll34', organizedData);
          navigate('/quali-1');
        }
        return;
      }
    }, 5000);
  };
     

  const sendToWebhook = async (url, data) => {
    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error('Error sending data to webhook:', error);
    }
  };

  const handleOptionClick = (option) => {
    if (step === 2) {
      setStep2Options((prev) => (prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]));
    } else if (step === 3) {
      if (option === 'Outro') {
        setStep3OtherOption((prev) => !prev);
        setOtherText('');
        setStep3Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      } else {
        setStep3Options((prev) =>
          prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
        );
      }
    } else if (step === 4) {
      if (option === 'Outro') {
        setStep4OtherOption((prev) => !prev);
        setOtherText('');
        setStep4Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      } else {
        setStep4Options((prev) =>
          prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
        );
      }
    } else if (step === 7) {
      setStep7Options((prev) => {
        const newOptions = prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option];
        setFormData((prevData) => ({
          ...prevData,
          selectedOptions: newOptions,
        }));
        return newOptions;
      });
    } else if (step === 6) {
      if (option === 'Outro') {
        setStep6OtherOption((prev) => !prev);
        setOtherText('');
        setStep6Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      } else {
        setStep6Options((prev) =>
          prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
        );
      }
    } else if (step === 7) {
      if (option === 'Outro') {
        setStep7OtherOption((prev) => !prev);
        setOtherText('');
        setStep7Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      } else {
        setStep7Options((prev) =>
          prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
        );
      }
    }    
  };

  const handleOtherOption = (step) => {
    if (step === 3) {
      setStep3OtherOption((prev) => !prev);
      setOtherText('');
      setStep3Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      setFormData((prevData) => ({
        ...prevData,
        selectedOptions: prevData.selectedOptions.filter((opt) => opt !== 'Outro'),
      }));
    } else if (step === 4) {
      setStep4OtherOption((prev) => !prev);
      setOtherText('');
      setStep4Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      setFormData((prevData) => ({
        ...prevData,
        selectedOptions: prevData.selectedOptions.filter((opt) => opt !== 'Outro'),
      }));
    } else if (step === 6) {
      setStep6OtherOption((prev) => !prev);
      setOtherText('');
      setStep6Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      setFormData((prevData) => ({
        ...prevData,
        selectedOptions: prevData.selectedOptions.filter((opt) => opt !== 'Outro'),
      }));
    } else if (step === 7) {
      setStep7OtherOption((prev) => !prev);
      setOtherText('');
      setStep7Options((prevOptions) => prevOptions.filter((opt) => opt !== 'Outro'));
      setFormData((prevData) => ({
        ...prevData,
        selectedOptions: prevData.selectedOptions.filter((opt) => opt !== 'Outro'),
      }));
    }    
  };
  

  const handleOtherTextChange = (e) => {
    setOtherText(e.target.value);
  
    if (step === 3) {
      setStep3Options((prevOptions) => {
        const optionsWithoutOther = prevOptions.filter((opt) => opt !== otherText);
        const updatedOptions = e.target.value ? [...optionsWithoutOther, e.target.value] : optionsWithoutOther;
        
        setFormData((prevData) => ({
          ...prevData,
          selectedOptions: updatedOptions,
        }));
        
        return updatedOptions;
      });
    } else if (step === 4) {
      setStep4Options((prevOptions) => {
        const optionsWithoutOther = prevOptions.filter((opt) => opt !== otherText);
        const updatedOptions = e.target.value ? [...optionsWithoutOther, e.target.value] : optionsWithoutOther;
        
        setFormData((prevData) => ({
          ...prevData,
          selectedOptions: updatedOptions,
        }));
  
        return updatedOptions;
      });
    } else if (step === 6) {
      setFormData((prevData) => ({
        ...prevData,
        step6OtherOption: e.target.value, // Salva o valor digitado no formData
      }));
    } else if (step === 7) {
      setFormData((prevData) => ({
        ...prevData,
        step7OtherOption: e.target.value, // Salva o valor digitado no formData
      }));
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Calcula a porcentagem de progresso com base no step atual
  const progressPercentage = (step / totalSteps) * 100;

  // Loader styles
  const loaderStyle = {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #37bdc6',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    margin: '0 auto', // Centraliza o loader
  };

  const handleNextStepAndSendData = () => {
    // Defina uma estrutura específica para capturar os dados com base no step atual
    let dataToSend = { ...formData, currentStep: step };
  
    // Adicione informações adicionais com base no step atual (5 e 6)
    if (step === 5) {
      dataToSend = { ...dataToSend, budget: formData.budget || 'Não informado' };
    }
  
    if (step === 6 && step6OtherOption) {
      dataToSend = { ...dataToSend, step6Options: [...step6Options, step6OtherOption] };
    }
  
    if (step === 7 && step7OtherOption) {
      dataToSend = { ...dataToSend, step7Options: [...step7Options, step7OtherOption] };
    }
  
    // Envia as informações específicas do step atual
    sendToWebhook('https://hook.us2.make.com/step-data', dataToSend);
    nextStep();
  };

  // Ajuste para capturar opções específicas de orçamento no step 5
  const handleBudgetOptionClick = (budgetOption) => {
    setFormData((prevData) => ({
      ...prevData,
      budget: budgetOption, // Atualiza a seleção de orçamento
    }));
    handleNextStepAndSendData(); // Envia e avança o step
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-white pt-4 px-4 mb-4">
       <div className="w-full max-w-md flex justify-center mt-4 mb-4">
        <img src={logo} alt="Logo" className="h-12" />
      </div>

      {/* Barra de progresso */}
      <div className="w-full max-w-md mb-8 px-4">
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-full bg-[#37bdc6] rounded-full transition-all duration-500"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>

      <div className="text-center w-full max-w-md px-4">
        {step === 1 && (
          <div>
            <img
              src="/imgs/VBMC.jpeg"
              alt="Step 1"
              className="mx-auto mb-6 w-full max-w-md h-[800px] rounded"
            />
            <button
              onClick={handleNextStepAndSendData}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              Iniciar
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Qual o faturamento anual da sua empresa?</h2>
            <div className="flex flex-col gap-4">
            <button
              onClick={() => {
                handleOptionClick('Até R$ 4,8 milhões /ano');
                handleNextStepAndSendData();
              }}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              Até R$ 4,8 milhões /ano
            </button>

            <button
              onClick={() => {
                handleOptionClick('De R$ 4,8 milhões até R$ 10 milhões /ano');
                handleNextStepAndSendData();
              }}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              De R$ 4,8 até R$ 10 milhões /ano
            </button>

            <button
              onClick={() => {
                handleOptionClick('De R$ 10 milhões até R$ 15 milhões /ano');
                handleNextStepAndSendData();
              }}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              De R$ 10 até R$ 15 milhões /ano
            </button>

            <button
              onClick={() => {
                handleOptionClick('De R$ 15 milhões até R$ 18 milhões /ano');
                handleNextStepAndSendData();
              }}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              De R$ 15 até R$ 18 milhões /ano
            </button>

            <button
              onClick={() => {
                handleOptionClick('De R$ 18 milhões até R$ 50 milhões /ano');
                handleNextStepAndSendData();
              }}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              De R$ 18 até R$ 50 milhões /ano
            </button>

            <button
              onClick={() => {
                handleOptionClick('Acima de R$ 50 milhões /ano');
                handleNextStepAndSendData();
              }}
              className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300"
            >
              Acima de R$ 50 milhões /ano
            </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Em qual área da sua empresa você necessita de ajuda especializada?
            </h2>
            <p className="text-sm text-gray-500 mb-2">Você pode escolher uma ou mais opções.</p>
            <div className="flex flex-col gap-4">
              <button
                onClick={() => handleOptionClick('Gestão de Vendas')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Gestão de Vendas')
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Gestão de Vendas
              </button>

              <button
                onClick={() => handleOptionClick('Gestão Financeira')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Gestão Financeira')
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Gestão Financeira
              </button>

              <button
                onClick={() => handleOptionClick('Gestão Estratégica')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Gestão Estratégica') 
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Gestão Estratégica
              </button>

              <button
                onClick={() => handleOptionClick('Gestão da Produção')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Gestão da Produção') 
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Gestão da Produção
              </button>

              <button
                onClick={() => handleOptionClick('Gestão de Suprimentos')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Gestão de Suprimentos') 
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Gestão de Suprimentos
              </button>

              <button
                onClick={() => handleOptionClick('Gestão de Pessoas')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Gestão de Pessoas') 
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Gestão de Pessoas
              </button>

              <button
                onClick={() => handleOptionClick('Sucessão da empresa')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Sucessão da empresa') 
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Sucessão da empresa
              </button>

              <button
                onClick={() => handleOptionClick('Contabilidade')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3Options.includes('Contabilidade') 
                    ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Contabilidade
              </button>
              
              <button
                onClick={() => handleOtherOption(3)}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step3OtherOption ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Outro
              </button>

              {/* Entrada de texto para "Outro" */}
              {step3OtherOption && (
                <div className="mt-4">
                  <textarea
                    placeholder="Por favor, especifique..."
                    className="w-full max-w-md p-2 border-[0.7px] border-black rounded-md"
                    value={otherText}
                    onChange={handleOtherTextChange}
                    required
                  />
                </div>
              )}

              {/* Botão Avançar com Validação */}
              <button
                onClick={() => {
                  if (step3OtherOption && otherText) {
                    setFormData((prevData) => ({
                      ...prevData,
                      selectedOptions: [...prevData.selectedOptions, otherText],
                    }));
                  }
                  nextStepWithValidation();
                }}
                className={`mt-4 w-full max-w-md py-4 border-[0.7px] text-lg rounded-md transition-all duration-300 ${
                  step3Options.length > 0 || (step3OtherOption && otherText) ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={step3Options.length === 0 && (!step3OtherOption || !otherText)}
              >
                Avançar
              </button>

            </div>
          </div>
        )}

        {step === 4 && (
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Qual é o principal desafio que sua empresa enfrenta atualmente?</h2>
            <p className="text-sm text-gray-500 mb-2">Você pode escolher uma ou mais opções.</p>
            <div className="flex flex-col gap-4">
              <button
                onClick={() => handleOptionClick('Aumento de faturamento')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Aumento de faturamento') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Aumento de faturamento
              </button>
              <button
                onClick={() => handleOptionClick('Melhoria nos processos')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Melhoria nos processos') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Melhoria nos processos
              </button>
              <button
                onClick={() => handleOptionClick('Melhoria no nível de gestão')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Melhoria no nível de gestão') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Melhoria no nível de gestão
              </button>
              <button
                onClick={() => handleOptionClick('Capacitação de pessoas')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Capacitação de pessoas') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Capacitação de pessoas
              </button>
              <button
                onClick={() => handleOptionClick('Expansão para novos mercados')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Expansão para novos mercados') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Expansão para novos mercados
              </button>
              <button
                onClick={() => handleOptionClick('Implementação de novas tecnologia de gestão')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Implementação de novas tecnologia de gestão') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Implementação de novas tecnologia de gestão
              </button>
              <button
                onClick={() => handleOptionClick('Redução de custos e despesas')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4Options.includes('Redução de custos e despesas') ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Redução de custos e despesas
              </button>

              <button
                onClick={() => handleOtherOption(4)}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step4OtherOption ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-white text-black hover:bg-gray-400 hover:text-white'
                }`}
              >
                Outro
              </button>


              {/* Exibe o campo de texto se "Outro" for selecionado */}
              {step4OtherOption && (
                <div className="mt-4">
                  <textarea
                    placeholder="Por favor, especifique..."
                    className="w-full max-w-md p-2 border-[0.7px] border-black rounded-md"
                    value={otherText}
                    onChange={handleOtherTextChange}
                    required
                  />
                </div>
              )}


              {/* Botão Avançar */}
              <button
                onClick={() => {
                  if (step4OtherOption && otherText) {
                    setFormData((prevData) => ({
                      ...prevData,
                      selectedOptions: [...prevData.selectedOptions, otherText],
                    }));
                  }
                  nextStepWithValidation();
                }}
                className={`mt-4 w-full max-w-md py-4 border-[0.7px] text-lg rounded-md transition-all duration-300 ${
                  step4Options.length > 0 || (step4OtherOption && otherText) ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={step4Options.length === 0 && (!step4OtherOption || !otherText)}
              >
                Avançar
              </button>

            </div>
          </div>
        )}
        
        {step === 5 && (
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Qual é o seu orçamento mensal estimado para investir em consultoria de gestão?</h2>
            <div className="flex flex-col gap-4">
              <button onClick={() => handleBudgetOptionClick('Até R$ 10 mil /mês')} className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300">
                Até R$ 10 mil /mês
              </button>
              <button onClick={() => handleBudgetOptionClick('Entre R$ 10 a R$ 20 mil /mês')} className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300">
                Entre R$ 10 a R$ 20 mil /mês
              </button>
              <button onClick={() => handleBudgetOptionClick('Entre R$ 20 a R$ 50 mil /mês')} className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300">
                Entre R$ 20 a R$ 50 mil /mês
              </button>
              <button onClick={() => handleBudgetOptionClick('Acima de R$ 50 mil /mês')} className="w-full max-w-md py-4 border-[0.7px] border-black text-black text-lg rounded-md hover:bg-[#37bdc6] hover:text-white hover:border-white transition-all duration-300">
                Acima de R$ 50 mil /mês
              </button>
            </div>
          </div>
        )}

{step === 6 && (
  <div>
    <h2 className="text-2xl font-bold text-gray-800 mb-4">
      Qual o nível de maturidade de gestão da sua empresa?
    </h2>
    <div className="flex flex-col gap-4">
      <button
        onClick={() => handleOptionClick('Desconheço')}
        className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
          step6Options.includes('Desconheço')
            ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
            : 'bg-white text-black hover:bg-gray-400 hover:text-white'
        }`}
      >
        <span className="font-bold">Desconheço</span>
      </button>

      <button
        onClick={() => handleOptionClick('Básico')}
        className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
          step6Options.includes('Básico')
            ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
            : 'bg-white text-black hover:bg-gray-400 hover:text-white'
        }`}
      >
        <span className="font-bold">Básico</span>
        <p className="text-sm text-gray-500">
          Controle limitado de caixa e vendas, equipes pouco engajadas, baixa informatização.
        </p>
      </button>

      <button
        onClick={() => handleOptionClick('Intermediário')}
        className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
          step6Options.includes('Intermediário')
            ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
            : 'bg-white text-black hover:bg-gray-400 hover:text-white'
        }`}
      >
        <span className="font-bold">Intermediário</span>
        <p className="text-sm text-gray-500">
          Uso moderado de ferramentas, metas comerciais, engajamento médio das equipes, informatização parcial.
        </p>
      </button>

      <button
        onClick={() => handleOptionClick('Avançado')}
        className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
          step6Options.includes('Avançado')
            ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
            : 'bg-white text-black hover:bg-gray-400 hover:text-white'
        }`}
      >
        <span className="font-bold">Avançado</span>
        <p className="text-sm text-gray-500">
          Múltiplas ferramentas, metas monitoradas, alto engajamento, avaliação de desempenho estruturada.
        </p>
      </button>

      <button
        onClick={() => handleOtherOption(6)}
        className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
          step6OtherOption ? 'bg-[#37bdc6] text-white' : 'bg-white text-black'
        }`}
      >
        Outro
      </button>

      {step6OtherOption && (
        <div className="mt-4">
          <textarea
            placeholder="Por favor, especifique..."
            className="w-full max-w-md p-2 border border-black rounded-md"
            value={otherText}
            onChange={handleOtherTextChange}
            required
          />
        </div>
      )}

      {/* Botão Avançar */}
      <button
        onClick={() => {
          if (step6OtherOption && otherText) {
            setFormData((prevData) => ({
              ...prevData,
              selectedOptions: [...prevData.selectedOptions, otherText],
            }));
          }
          nextStepWithValidation();
        }}
        className={`mt-4 w-full max-w-md py-4 border-[0.7px] text-lg rounded-md transition-all duration-300 ${
          step6Options.length > 0 || (step6OtherOption && otherText)
            ? 'bg-[#37bdc6] text-white hover:bg-[#2da5a4]'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`}
        disabled={step6Options.length === 0 && (!step6OtherOption || !otherText)}
      >
        Avançar
      </button>
    </div>
  </div>
)}


        {step === 7 && (
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Qual a expectativa quanto a atuação da consultoria na sua empresa?</h2>
            <p className="text-sm text-gray-500 mb-2">Você pode escolher uma ou mais opções.</p>
            <div className="flex flex-col gap-4">
              <button
                onClick={() => handleOptionClick('Diagnóstico e recomendação estratégica')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  formData.selectedOptions.includes('Diagnóstico e recomendação estratégica')
                    ? 'bg-[#37bdc6] text-white hover:bg-[#37bdc6] hover:border-white'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white hover:border-white'
                }`}
              >
                Diagnóstico e recomendação estratégica
              </button>

              <button
                onClick={() => handleOptionClick('Geração de resultado através de implementação de soluções de gestão')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  formData.selectedOptions.includes('Geração de resultado através de implementação de soluções de gestão')
                    ? 'bg-[#37bdc6] text-white hover:bg-[#37bdc6] hover:border-white'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white hover:border-white'
                }`}
              >
                Geração de resultado através de implementação de soluções de gestão
              </button>

              <button
                onClick={() => handleOptionClick('Programa de capacitação de equipe')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  formData.selectedOptions.includes('Programa de capacitação de equipe')
                    ? 'bg-[#37bdc6] text-white hover:bg-[#37bdc6] hover:border-white'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white hover:border-white'
                }`}
              >
                Programa de capacitação de equipe
              </button>

              <button
                onClick={() => handleOptionClick('Revisão de Processos')}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  formData.selectedOptions.includes('Revisão de Processos')
                    ? 'bg-[#37bdc6] text-white hover:bg-[#37bdc6] hover:border-white'
                    : 'bg-white text-black hover:bg-gray-400 hover:text-white hover:border-white'
                }`}
              >
                Revisão de Processos
              </button>

              <button
                onClick={() => handleOtherOption(7)}
                className={`w-full max-w-md py-4 border-[0.7px] border-black text-lg rounded-md transition-all duration-300 ${
                  step7OtherOption ? 'bg-[#37bdc6] text-white' : 'bg-white text-black'
                }`}
              >
                Outro
              </button>

              {step7OtherOption && (
                <div className="mt-4">
                  <textarea
                    placeholder="Por favor, especifique..."
                    className="w-full max-w-md p-2 border border-black rounded-md"
                    value={otherText}
                    onChange={handleOtherTextChange}
                    required
                  />
                </div>
              )}


              {/* Botão Avançar */}
              <button
                onClick={nextStepWithValidation}
                className={`mt-4 w-full max-w-md py-4 border-[0.7px] text-lg rounded-md transition-all duration-300 ${
                  step7Options.length > 0 ? 'bg-[#37bdc6] text-white hover:bg-[#37bdc6]-600 hover:border-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={step7Options.length === 0}
              >
                Avançar
              </button>
            </div>
          </div>
        )}
        
        {isAnalyzing ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Estamos analisando suas respostas...
            </h2>
            <div style={loaderStyle}></div>
          </div>
        ) : (
          <>
            {step === 8 && (
              <form onSubmit={handleFormSubmit} className="w-full max-w-lg mx-auto bg-gray-100 p-8 rounded-lg shadow-lg">
                {/* Nome da Empresa */}
                <div className="mb-4">
                  <label htmlFor="companyName" className="block text-left text-gray-700 mb-2">Nome da Empresa:</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#37bdc6]"
                    placeholder="Digite o nome da empresa"
                    required
                  />
                </div>

                {/* Nome */}
                <div className="mb-4">
                  <label htmlFor="name" className="block text-left text-gray-700 mb-2">Nome:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#37bdc6]"
                    placeholder="Digite seu nome"
                    required
                  />
                </div>

                {/* Cargo */}
                <div className="mb-4">
                  <label htmlFor="position" className="block text-left text-gray-700 mb-2">Cargo:</label>
                  <select
                    id="position"
                    name="position"
                    value={formData.position}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded bg-white focus:ring-2 focus:ring-[#37bdc6]"
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="Proprietário">Proprietário</option>
                    <option value="Sócio">Sócio</option>
                    <option value="CEO">CEO</option>
                    <option value="Diretor">Diretor</option>
                    <option value="Gerente">Gerente</option>
                    <option value="Analista">Analista</option>
                    <option value="Assistente">Assistente</option>
                    <option value="Estagiário">Estagiário</option>
                    <option value="Consultor">Consultor</option>
                    <option value="Outro">Outro</option>
                  </select>

                  {formData.position === 'Outro' && (
                    <input
                      label="Por favor, especifique"
                      name="otherPosition"
                      id="otherPosition"
                      type="text"
                      value={formData.otherPosition}
                      onChange={handleChange}
                      className="mt-2 p-2 border border-gray-300 rounded w-full focus:ring-2 focus:ring-[#37bdc6]"
                      required
                      placeholder="Especifique seu cargo"
                    />
                  )}
                </div>

                {/* Número de Telefone */}
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="block text-left text-gray-700 mb-2">Número de Telefone:</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#37bdc6]"
                    placeholder="Digite seu número de telefone"
                    required
                  />
                </div>

                {/* Email */}
                <div className="mb-4">
                  <label htmlFor="email" className="block text-left text-gray-700 mb-2">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#37bdc6]"
                    placeholder="Digite seu email"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#37bdc6] text-white py-2 rounded-md hover:bg-[#37bdc6]-600 transition-all duration-300"
                >
                  Enviar
                </button>
              </form>
            )}

          </>
        )}
      </div>
    </div>
  );
};

export default FormPage;
