import React from 'react';
import logo from '../../src/logo.png';

const Header = () => {
  return (
    <header className="fixed top-0 w-full bg-white py-4 flex justify-center items-center z-50 rounded">
      <img src={logo} alt="Logo" className="h-12" />
    </header>
  );
};

export default Header;
