import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { doc, setDoc, updateDoc, increment } from "firebase/firestore";

const HomePage = () => {
  // Definir as variações dentro de useMemo para evitar mudanças a cada renderização
  const titles = useMemo(() => ['Sua Gestão Está Patinando? Hora de Virar o Jogo!', 'Cansado de Ver Sua Empresa Ficar Para Trás?', 'Sua Estratégia Não Está Funcionando — E Isso Está Custando Caro!', 'Seus Processos Estão Te Afundando? Vamos Resgatar Seu Crescimento.', 'Está Perdendo o Controle? Sua Empresa Merece Uma Gestão Eficiente.', 'Liderar Sem Direção Só Acelera o Fracasso.', 'Sua Empresa Não Precisa de Mais Reuniões — Precisa de Soluções!', 'Resultados Abaixo do Esperado? O Problema Está na Gestão.', 'Quer Continuar Improvisando ou Finalmente Alcançar Resultados Sólidos?', 'Sua Empresa Está Desacelerando? Não Espere Pelo Pior.', '73,8% das médias empresas não têm metas claras para todas as áreas. Você sabe onde quer chegar?', 'Apenas 5% das empresas possuem um modelo de gestão estruturado. Está na hora de mudar essa estatística.', '76% das empresas não têm metas alinhadas entre setores. Isso está freando o seu crescimento!', 'Quase 20% das empresas brasileiras não possuem qualquer modelo de gestão definido. E a sua?', 'Mais de 60% das empresas brasileiras afirmam que seu modelo de gestão é ineficiente. Como está a sua gestão?'], []);
  const subtitles = useMemo(() => ['Se você já investiu tempo e dinheiro e os resultados não aparecem, assista ao vídeo abaixo e veja como transformar sua gestão.', 'Seus concorrentes estão avançando enquanto você ainda resolve problemas internos. Veja como podemos ajudar a mudar esse cenário.', 'Sua gestão está falhando e você não sabe por quê. Assista ao vídeo e descubra como evitar mais prejuízos.', 'A falta de uma estratégia sólida está freando seu crescimento. Veja como solucionamos isso com nossos métodos.', 'Se a incerteza está afetando suas decisões, assista ao vídeo e veja como podemos trazer clareza e foco para sua gestão.', 'Cada decisão sem direção clara está custando caro. Veja como uma consultoria especializada pode mudar esse rumo rapidamente.', 'Se você não sabe onde sua empresa está perdendo dinheiro e tempo, assista ao vídeo para entender e, em seguida, agende uma reunião.', 'Um diagnóstico estratégico é o primeiro passo para reverter seus resultados negativos. Veja como fazemos isso com precisão.', 'Se você está pronto para destravar o potencial oculto da sua empresa, assista ao vídeo e saiba como iniciar essa transformação agora.', 'Não deixe mais os problemas minarem o sucesso da sua empresa. Assista ao vídeo e preencha o formulário para mudar essa realidade.', '62,7% das empresas não consideram seu modelo de gestão eficiente. E a sua?'], []);
  const thumbs = useMemo(() => [
    'imgs/01.jpeg',
    'imgs/02.jpeg',
    'imgs/03.jpeg',
    'imgs/04.jpeg',
    'imgs/05.jpeg',
    'imgs/06.jpeg'
  ], []);
  const ctas = useMemo(() => ['Quero aplicar agora', 'Resolver agora', 'Descobrir mais', 'Mudar a rota', 'Transformar já', 'Quero saber mais', 'Transforme minha gestão', 'Agendar diagnóstico', 'Solicite uma reunião', 'Fale com um especialista', 'Vamos conversar', 'Comece agora', 'Descubra o potencial', 'Ver como funciona', 'Solicitar proposta'], []);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [thumb, setThumb] = useState("");
  const [cta, setCta] = useState("");
  const [userId, setUserId] = useState("");

  const [timeLeft, setTimeLeft] = useState(90); // 300 segundos (5 minutos)
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // Controla se o vídeo está tocando

  // Hook principal para inicialização das variáveis e lógica
  useEffect(() => {
    // Pegar o userId do localStorage, ou criar um novo se não existir
    let uniqueId = localStorage.getItem('userId');
    if (!uniqueId) {
      uniqueId = `user_${Math.random().toString(36).substring(7)}`;
      localStorage.setItem('userId', uniqueId);
    }
    setUserId(uniqueId);

    // Verificar se já existe uma combinação armazenada no localStorage
    const storedTitle = localStorage.getItem('title');
    const storedSubtitle = localStorage.getItem('subtitle');
    const storedThumb = localStorage.getItem('thumb');
    const storedCta = localStorage.getItem('cta');

    if (storedTitle && storedSubtitle && storedThumb && storedCta) {
      // Se existir, usar os valores armazenados
      setTitle(storedTitle);
      setSubtitle(storedSubtitle);
      setThumb(storedThumb);
      setCta(storedCta);
    } else {
      // Se não existir, definir variações aleatórias para cada elemento e armazenar no localStorage
      const titleIndex = Math.floor(Math.random() * titles.length);
      const subtitleIndex = Math.floor(Math.random() * subtitles.length);
      const thumbIndex = Math.floor(Math.random() * thumbs.length);
      const ctaIndex = Math.floor(Math.random() * ctas.length);

      const selectedTitle = titles[titleIndex];
      const selectedSubtitle = subtitles[subtitleIndex];
      const selectedThumb = thumbs[thumbIndex];
      const selectedCta = ctas[ctaIndex];

      setTitle(selectedTitle);
      setSubtitle(selectedSubtitle);
      setThumb(selectedThumb);
      setCta(selectedCta);

      // Armazenar no localStorage para manter a consistência
      localStorage.setItem('title', selectedTitle);
      localStorage.setItem('subtitle', selectedSubtitle);
      localStorage.setItem('thumb', selectedThumb);
      localStorage.setItem('cta', selectedCta);

      // Armazenar a combinação escolhida para este usuário no Firestore
      const userTestDoc = doc(db, "abTests", uniqueId);
      setDoc(userTestDoc, {
        title: selectedTitle,
        subtitle: selectedSubtitle,
        thumb: selectedThumb,
        cta: selectedCta,
        clicks: 0,
        confirmCall: 0,
        qualificado: 0,
        impressions: 1
      });
    }

    // Controle do temporizador para exibir o botão após o tempo expirar
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsButtonVisible(true);
    }
  }, [titles, subtitles, thumbs, ctas, timeLeft]);

  // Função para formatar o temporizador
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Função para registrar o clique no Firestore
  const handleButtonClick = async () => {
    const userTestDoc = doc(db, "abTests", userId);
    await updateDoc(userTestDoc, { clicks: increment(1) });
  };

  // Controla o início do vídeo
  const handlePlayClick = () => {
    setIsPlaying(true);
    const videoElement = document.getElementById('localVideo');
    if (videoElement) {
      videoElement.play();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white bg-grid">
      <div className="text-center w-full relative mt-12">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-4 max-w-4xl mx-auto">
        {title}
      </h1>
      <h2 className="text-lg md:text-xl lg:text-2xl font-medium text-gray-600 mb-8 max-w-4xl mx-auto">
        {subtitle}
      </h2>

      <div className="w-full max-w-4xl mx-auto aspect-video shadow-lg rounded-lg relative">
  <video
    id="localVideo"
    className="w-full h-full rounded-lg"
    src="/imgs/VSL.mp4"
    controls={true}
    preload="auto"
  ></video>

  {/* Imagem e botão play sobre o vídeo */}
  {!isPlaying && (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
      <img
        src={`/${thumb}`}
        alt="Thumbnail"
        className="absolute inset-0 w-full h-full object-cover rounded-lg"
      />
      <button
        className="relative z-10 bg-green-500 text-white p-4 rounded-full hover:bg-green-700 transition-all duration-300"
        onClick={handlePlayClick}
      >
        <svg
          className="w-12 h-12 animate-pulse"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14.752 11.168l-5.197-3.028A1 1 0 008 9.06v5.878a1 1 0 001.555.832l5.197-3.028a1 1 0 000-1.664z"
          ></path>
        </svg>
      </button>
    </div>
  )}
</div>

        {/* Contagem regressiva */}
        {!isButtonVisible && (
          <div className="mt-8 text-3xl font-semibold text-green-600">
            {formatTime(timeLeft)}
          </div>
        )}

        {/* Botão que aparece quando o tempo acabar */}
        {isButtonVisible && (
          <div className="mt-12">
            <Link
              to="/form"
              className="mt-8 px-8 py-4 bg-orange-500 text-white font-semibold text-xl rounded-lg hover:bg-orange-600 transition-all duration-300"
              onClick={handleButtonClick}
            >
              {cta}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
